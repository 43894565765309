"use client"

import React from "react"

export default function InterviewQuestionGenerator(props) {
    const { brandColor = "#6474fc" } = props

    // State variables
    const [jobTitle, setJobTitle] = React.useState("")
    const [customJobTitle, setCustomJobTitle] = React.useState("")
    const [jobLevel, setJobLevel] = React.useState("")
    const [customJobLevel, setCustomJobLevel] = React.useState("")
    const [questionType, setQuestionType] = React.useState("")
    const [company, setCompany] = React.useState("")
    const [jobDescription, setJobDescription] = React.useState("")
    const [questions, setQuestions] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    const [generating, setGenerating] = React.useState(false)
    const [copied, setCopied] = React.useState(false)
    const [error, setError] = React.useState(null)
    const [showResults, setShowResults] = React.useState(false)

    // Options for dropdowns
    const jobTitles = [
        "Software Engineer",
        "Frontend Developer",
        "Backend Developer",
        "Product Manager",
        "UX Designer",
        "Data Scientist",
        "AI Researcher",
        "Machine Learning Engineer",
        "Full Stack Developer",
        "Other",
    ]

    const seniorityLevels = [
        "Intern",
        "Junior",
        "Mid",
        "Senior",
        "Lead",
        "Principal",
        "Other",
    ]

    const questionTypes = [
        "Technical",
        "Behavioral",
        "System Design",
        "Leadership",
        "Team Fit",
        "Problem Solving",
        "Culture Fit",
        "Creativity",
        "Other",
    ]

    // For word-by-word animation
    const [displayedQuestions, setDisplayedQuestions] = React.useState([])

    // Generate questions function
    const handleGenerate = async () => {
        const titleToUse = jobTitle === "Other" ? customJobTitle : jobTitle
        const levelToUse = jobLevel === "Other" ? customJobLevel : jobLevel

        if (!titleToUse || !jobDescription) {
            setError("Please enter job title and job description.")
            return
        }

        setLoading(true)
        setQuestions([])
        setDisplayedQuestions([])
        setError(null)
        setShowResults(true)
        setGenerating(true)

        const prompt = `
Generate 10 advanced, unique, high-quality interview questions for a ${
            levelToUse ? levelToUse + " " : ""
        }${titleToUse} role${questionType ? " focusing on " + questionType + " questions" : ""} at ${company || "a tech company"}.
These questions should match the job description below and challenge even experienced candidates.
Avoid intros, and format as a numbered list like 1. ... 2. ... up to 10.
Avoid any bullets or markdown formatting.

Job description:
${jobDescription}
`

        try {
            const response = await fetch(
                "https://api.openai.com/v1/chat/completions",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer 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`,
                    },
                    body: JSON.stringify({
                        model: "gpt-4o",
                        messages: [{ role: "user", content: prompt }],
                        temperature: 0.7,
                    }),
                }
            )

            if (!response.ok) {
                const errorData = await response.json()
                throw new Error(
                    errorData.error?.message || "API request failed"
                )
            }

            const data = await response.json()
            const content = data.choices?.[0]?.message?.content || ""

            if (!content) {
                throw new Error("No content received from OpenAI.")
            }

            const parsed = content
                .split(/\n(?=\d+\.)/)
                .map((q) => q.trim())
                .filter(Boolean)

            setQuestions(parsed)

            // Animate the questions appearing word by word
            const animateQuestions = async () => {
                const tempQuestions = [...parsed]
                setDisplayedQuestions([])

                for (let i = 0; i < tempQuestions.length; i++) {
                    const question = tempQuestions[i]
                    setDisplayedQuestions((prev) => [...prev, question])

                    // Pause between questions
                    await new Promise((resolve) => setTimeout(resolve, 300))
                }

                setGenerating(false)
            }

            animateQuestions()
        } catch (err) {
            console.error("Error:", err)
            setError(err.message || "Something went wrong. Please try again.")
            setGenerating(false)
        } finally {
            setLoading(false)
        }
    }

    // Copy to clipboard function
    const handleCopy = () => {
        navigator.clipboard.writeText(questions.join("\n"))
        setCopied(true)
        setTimeout(() => setCopied(false), 2000)
    }

    return (
        <div style={styles.container}>
            {/* Form Section */}
            <div style={styles.formContainer}>
                <h2 style={{ ...styles.heading, color: brandColor }}>
                    Generate Your Interview Questions
                </h2>

                {error && <div style={styles.errorMessage}>{error}</div>}

                <div style={styles.formGroup}>
                    <label style={styles.label}>Job Title *</label>
                    <select
                        value={jobTitle}
                        onChange={(e) => setJobTitle(e.target.value)}
                        style={styles.select}
                    >
                        <option value="">-- Select Job Title --</option>
                        {jobTitles.map((title) => (
                            <option key={title} value={title}>
                                {title}
                            </option>
                        ))}
                    </select>
                    {jobTitle === "Other" && (
                        <input
                            type="text"
                            value={customJobTitle}
                            onChange={(e) => setCustomJobTitle(e.target.value)}
                            placeholder="Enter your job title"
                            style={{ ...styles.input, marginTop: "8px" }}
                        />
                    )}
                </div>

                <div style={styles.formGroup}>
                    <label style={styles.label}>
                        Seniority Level (Optional)
                    </label>
                    <select
                        value={jobLevel}
                        onChange={(e) => setJobLevel(e.target.value)}
                        style={styles.select}
                    >
                        <option value="">-- Select Level --</option>
                        {seniorityLevels.map((level) => (
                            <option key={level} value={level}>
                                {level}
                            </option>
                        ))}
                    </select>
                    {jobLevel === "Other" && (
                        <input
                            type="text"
                            value={customJobLevel}
                            onChange={(e) => setCustomJobLevel(e.target.value)}
                            placeholder="Enter level"
                            style={{ ...styles.input, marginTop: "8px" }}
                        />
                    )}
                </div>

                <div style={styles.formGroup}>
                    <label style={styles.label}>Question Type (Optional)</label>
                    <select
                        value={questionType}
                        onChange={(e) => setQuestionType(e.target.value)}
                        style={styles.select}
                    >
                        <option value="">-- Select Type --</option>
                        {questionTypes.map((type) => (
                            <option key={type} value={type}>
                                {type}
                            </option>
                        ))}
                    </select>
                </div>

                <div style={styles.formGroup}>
                    <label style={styles.label}>Company (Optional)</label>
                    <input
                        type="text"
                        value={company}
                        onChange={(e) => setCompany(e.target.value)}
                        placeholder="Type the company name"
                        style={styles.input}
                    />
                </div>

                <div style={styles.formGroup}>
                    <label style={styles.label}>Job Description *</label>
                    <textarea
                        value={jobDescription}
                        onChange={(e) => setJobDescription(e.target.value)}
                        placeholder="Paste or write the JD here..."
                        style={styles.textarea}
                    />
                </div>

                <button
                    onClick={handleGenerate}
                    disabled={loading}
                    style={{
                        ...styles.button,
                        backgroundColor: brandColor,
                        opacity: loading ? 0.7 : 1,
                        cursor: loading ? "not-allowed" : "pointer",
                    }}
                >
                    {loading ? "Generating..." : "Generate Questions"}
                </button>
            </div>

            {/* Results Section - Always visible but empty until questions are generated */}
            <div style={styles.resultsContainer} id="results-section">
                <h2 style={{ ...styles.heading, color: brandColor }}>
                    Your 10 Interview Questions
                </h2>

                {showResults && (
                    <>
                        <div style={styles.questionsContainer}>
                            {displayedQuestions.map((question, index) => (
                                <div key={index} style={styles.questionItem}>
                                    <div
                                        style={{
                                            position: "relative",
                                            width: "36px",
                                            height: "36px",
                                            flexShrink: 0,
                                        }}
                                    >
                                        <div
                                            style={{
                                                position: "absolute",
                                                top: 0,
                                                left: 0,
                                                width: "100%",
                                                height: "100%",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                backgroundColor: brandColor,
                                                color: "white",
                                                fontWeight: "bold",
                                                fontSize: "18px",
                                                borderRadius: "50%",
                                            }}
                                        >
                                            {index + 1}
                                        </div>
                                    </div>
                                    <div style={styles.questionText}>
                                        {question.replace(/^\d+\.\s*/, "")}
                                    </div>
                                </div>
                            ))}

                            {generating && (
                                <div style={styles.generatingIndicator}>
                                    Generating questions
                                    <span className="dot-1">.</span>
                                    <span className="dot-2">.</span>
                                    <span className="dot-3">.</span>
                                </div>
                            )}
                        </div>

                        {displayedQuestions.length > 0 && (
                            <button
                                onClick={handleCopy}
                                style={{
                                    ...styles.button,
                                    backgroundColor: brandColor,
                                    marginTop: "20px",
                                }}
                            >
                                {copied ? "Copied!" : "Copy Questions"}
                            </button>
                        )}
                    </>
                )}
            </div>

            {/* Spacer to ensure content below is pushed down */}
            <div style={{ height: "100px" }}></div>
        </div>
    )
}

// Styles
const styles = {
    container: {
        width: "100%",
        maxWidth: "800px",
        margin: "0 auto",
        fontFamily:
            "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif",
        display: "flex",
        flexDirection: "column",
        gap: "30px",
        padding: "20px",
        fontSize: "18px", // Base font size increased
    },
    formContainer: {
        backgroundColor: "white",
        borderRadius: "12px",
        padding: "28px", // Increased padding
        boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
        width: "100%",
    },
    heading: {
        fontSize: "32px", // Increased from 24px
        fontWeight: "600",
        marginTop: 0,
        marginBottom: "24px", // Increased spacing
    },
    formGroup: {
        marginBottom: "20px", // Increased spacing
    },
    label: {
        display: "block",
        marginBottom: "8px", // Increased spacing
        fontWeight: "500",
        fontSize: "18px", // Increased from default
    },
    input: {
        width: "100%",
        padding: "12px 16px", // Increased padding
        fontSize: "18px", // Increased from 16px
        borderRadius: "8px",
        border: "1px solid #ddd",
        boxSizing: "border-box",
    },
    select: {
        width: "100%",
        padding: "12px 16px", // Increased padding
        fontSize: "18px", // Increased from 16px
        borderRadius: "8px",
        border: "1px solid #ddd",
        backgroundColor: "white",
        boxSizing: "border-box",
    },
    textarea: {
        width: "100%",
        padding: "12px 16px", // Increased padding
        fontSize: "18px", // Increased from 16px
        borderRadius: "8px",
        border: "1px solid #ddd",
        minHeight: "120px", // Increased height
        resize: "vertical",
        boxSizing: "border-box",
    },
    button: {
        padding: "14px 24px", // Increased padding
        fontSize: "18px", // Increased from 16px
        fontWeight: "600",
        color: "white",
        border: "none",
        borderRadius: "8px",
        cursor: "pointer",
    },
    errorMessage: {
        backgroundColor: "#fff5f5",
        color: "#e53e3e",
        padding: "14px", // Increased padding
        borderRadius: "8px",
        marginBottom: "20px", // Increased spacing
        border: "1px solid #fed7d7",
        fontSize: "18px", // Increased font size
    },
    resultsContainer: {
        backgroundColor: "white",
        borderRadius: "12px",
        padding: "28px", // Increased padding
        boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
        width: "100%",
        marginBottom: "40px",
        minHeight: "200px", // Ensure it has height even when empty
    },
    questionsContainer: {
        display: "flex",
        flexDirection: "column",
        gap: "20px", // Increased spacing between questions
    },
    questionItem: {
        display: "flex",
        alignItems: "flex-start",
        gap: "16px", // Increased spacing
    },
    questionText: {
        fontSize: "18px", // Increased from 16px
        lineHeight: "1.6", // Slightly increased for better readability
        flex: 1,
    },
    generatingIndicator: {
        padding: "16px", // Increased padding
        textAlign: "center",
        color: "#666",
        fontStyle: "italic",
        fontSize: "18px", // Increased font size
    },
}
